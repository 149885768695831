const DEFAULT_STRATEGY = 'initial_rate'
const DEFAULT_SCENARIO = 'Fixed 2 - 4 year'

const statsLenderQuery = ({ currentLenderId }) => ({
  measures: [
    'CaseProductStats.count',
    'CaseProductStats.minByInitialRate',
  ],
  timeDimensions: [
    {
      dimension: 'CaseProductStats.updatedAt',
      granularity: 'year',
    },
  ],
  order: {},
  filters: [
    {
      dimension: 'CaseProductStats.caseType',
      operator: 'equals',
      values: [
        'buy_to_let',
      ],
    },
    {
      dimension: 'CaseProductStats.lenderId',
      operator: 'equals',
      values: [
        `${currentLenderId}`,
      ],
    },
    {
      dimension: 'CaseProductStats.scenarioOrderStrategy',
      operator: 'equals',
      values: [
        DEFAULT_STRATEGY,
      ],
    },
    {
      dimension: 'CaseProductStats.scenario',
      operator: 'equals',
      values: [
        DEFAULT_SCENARIO,
      ],
    },
  ],
})

export default ({ currentLenderId }) => [
  {
    id: '2-1',
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 9,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'mbtCasesBar', label: 'Bar' }, { value: 'mbtCasesTable', label: 'Table' }],
      },
      { name: 'measure' },
      { name: 'dimension' },
      { name: 'data_format', options: ['absolute', 'percentage'] },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: [
        ],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      dataFormat: 'absolute',
      chartType: 'mbtCasesBar',
      sessionGranularity: 'month',
    },
    name: 'Market Volume',
    description: 'Analyse the makeup of cases being researched on MBT using customer filters and dimensions. Export data to analyse and interrogate further.',
    __typename: 'DashboardItem',
    includedSegment: 'marketData',
    identifier: 'marketVolume',
  },
  {
    id: '2-2',
    newFeature: true,
    layout: {
      x: 12,
      y: 0,
      w: 12,
      h: 9,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'affordableIndexLine', label: 'Line' }, { value: 'affordableIndexTable', label: 'Table' }],
      },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.maxLendAmountAvg',
          'Cases.loanBorrowingAvg',
          'Cases.minLendAmountAvg',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: [],
        segments: [
          'Cases.affordable',
        ],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      dataFormat: 'absolute',
      chartType: 'affordableIndexLine',
      sessionGranularity: 'month',
    },
    name: 'Market Value',
    description: 'Identify the loan amount ranges of affordable lenders. Minimum and Maximum will show you spread between the lowest amount and highest amount offered by lenders.',
    __typename: 'DashboardItem',
    includedSegment: 'marketData',
    identifier: 'marketValue',
  },
  {
    id: '2-3',
    layout: {
      x: 0,
      y: 9,
      w: 12,
      h: 9,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'line', label: 'Line' }, { value: 'bar', label: 'Bar' }, { value: 'table', label: 'Table' },
        ],
      },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.affordablePercentage',
          'Cases.unaffordablePercentage',
          'Cases.unlendablePercentage',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      chartType: 'bar',
      yAxis: {
        formatter: '%',
        ticks: [0, 25, 50, 75, 100],
      },
      sessionGranularity: 'month',
    },
    name: 'Affordability Gap',
    description: 'From your defined customer set we can detail the percentage of cases that were affordable, unaffordable and will not lend across the whole of Market',
    __typename: 'DashboardItem',
    includedSegment: 'other',
  },
  {
    id: '2-4',
    layout: {
      x: 12,
      y: 9,
      w: 12,
      h: 9,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'line', label: 'Line' }, { value: 'bar', label: 'Bar' }, { value: 'table', label: 'Table' },
        ],
      },

    ],
    vizState: {
      query: {
        measures: [
          'CaseResults.affordablePercentage',
          'CaseResults.unaffordablePercentage',
          'CaseResults.unlendablePercentage',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        filters: [
          `CaseResults.LENDER.${currentLenderId}`,
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      chartType: 'bar',
      yAxis: {
        formatter: '%',
        ticks: [0, 25, 50, 75, 100],
      },
      sessionGranularity: 'month',
    },
    name: 'Your Affordability Performance',
    description: 'See the number of cases you were Affordable, Unaffordable and would not lend due to criteria. Data based on minimum loan returned.',
    __typename: 'DashboardItem',
    includedSegment: 'benchmarking',
    identifier: 'yourAffordabilityPerformance',
  },
  {
    id: '2-5',
    layout: {
      x: 0,
      y: 18,
      w: 12,
      h: 9,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'marketAffordabilityLine', label: 'Line' },
          { value: 'marketAffordabilityBar', label: 'Bar' },
          { value: 'marketAffordabilityTable', label: 'Table' },
        ],
      },

      { name: 'lenders' },

    ],
    vizState: {
      query: {
        measures: [
          'CaseResults.affordablePercentage',
          'CaseResults.unaffordablePercentage',
          'CaseResults.unlendablePercentage',
        ],
        dimensions: ['CaseResults.lenderName'],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      chartType: 'marketAffordabilityBar',
      lenders: [],
      yAxis: {
        formatter: '%',
        ticks: [0, 25, 50, 75, 100],
      },
      sessionGranularity: 'month',
    },
    name: 'Competitors - Affordability Performance',
    description: 'Benchmark how your competitors performed. You can narrow the analysis by multi selecting specific lenders.',
    __typename: 'DashboardItem',
    includedSegment: 'benchmarking',
    identifier: 'competitorsAffordabilityPerformance',
  },
  {
    id: '2-6',
    layout: {
      x: 12,
      y: 18,
      w: 12,
      h: 9,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'topXBarChart', label: 'Bar' }, { value: 'topXTable', label: 'Table' },
        ],
      },
      {
        name: 'top_position',
        options: [
          { value: 'Top1Lenders', label: 'TOP-1' },
          { value: 'TopAmong5Lenders', label: 'Among-5' },
          { value: 'TopAmong10Lenders', label: 'Among-10' },
        ],
      },

    ],
    vizState: {
      custom: 'afford_ranking',
      disabledLegend: true,
      disabledFilters: [3],
      query: [{
        measures: [
          'Top1Lenders.count',
        ],
        timeDimensions: [
          {
            dimension: 'Top1Lenders.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'Top1Lenders.lenderName',
        ],
        filters: [
          {
            dimension: 'Top1Lenders.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Top1Lenders.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }, {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }],
      chartType: 'topXBarChart',
      yAxis: {
        formatter: '%',
      },
      sessionGranularity: 'month',
    },
    name: 'Your Affordability Ranking',
    description: 'AFFORDABLE CASES: Look at how you perform when you are affordable. Change ranking view from Top 1 to Top 5 and Top 10.',
    __typename: 'DashboardItem',
    includedSegment: 'affordability',
    identifier: 'yourAffordabilityRanking',
  },
  {
    id: '2-7',
    newFeature: true,
    layout: {
      x: 0,
      y: 27,
      w: 12,
      h: 9,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'myDeviationBar', label: 'Bar' }, { value: 'myDeviationTable', label: 'Table' },
        ],
      },

    ],
    vizState: {
      query: {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: ['CaseResults.deviationFrames'],
        segments: [
          'CaseResults.unaffordable',
          'CaseResults.lendableSegment',
        ],
        filters: [
          `CaseResults.LENDER.${currentLenderId}`,
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      chartType: 'myDeviationBar',
      dataFormat: 'percentage',
      yAxis: {
        formatter: '%',
      },
      sessionGranularity: 'month',
    },
    name: 'Deviation from loan Requested',
    description: 'UNAFFORDABLE CASES: Where you did not meet the loan requested, we can provide insight on the percentage difference between the requested loan amount and the amount you offered.',
    __typename: 'DashboardItem',
    includedSegment: 'affordability',
    identifier: 'deviationFromLoanRequested',
  },
  {
    id: '2-14',
    layout: {
      x: 12,
      y: 18,
      w: 12,
      h: 9,
    },
    options: [
      { name: 'multi_lenders' },
    ],
    vizState: {
      disabledLegend: true,
      query: {
        measures: [
          'CaseResults.maxLendAmountAvg',
          'CaseResults.minLendAmountAvg',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.lender_id',
            operator: 'equals',
            values: [`${currentLenderId}`],
          },
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
        ],
      },
      lenders: [],
      chartType: 'line',
    },
    name: 'Loan amounts offered',
    description: 'Your average maximum and minimum loan offered, select competitors for comparison. Based on all cases.',
    __typename: 'DashboardItem',
    includedSegment: 'affordability',
    identifier: 'loanOfferedByLender',
  },
  {
    id: '2-8',
    newFeature: true,
    layout: {
      x: 12,
      y: 27,
      w: 12,
      h: 9,
    },
    options: [

    ],
    vizState: {

      disabledLegend: true,
      query: {
        measures: [
          'ExcludedReasons.count',
        ],

        timeDimensions: [
          {
            dimension: 'ExcludedReasons.updatedAt',
          },
        ],
        order: {},
        dimensions: [
          'ExcludedReasons.name',
        ],
        filters: [
          `ExcludedReasons.LENDER.${currentLenderId}`,
          {
            dimension: 'ExcludedReasons.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'ExcludedReasons.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      chartType: 'excludedReasonsTable',
    },
    ignoredGlobalOptions: ['groupingByTime'],
    name: 'Your Will not Lend',
    description: 'WILL NOT LEND CASES: See the specific criteria and count of times the criteria was not met.',
    __typename: 'DashboardItem',
    includedSegment: 'criteria',
    identifier: 'yourWillNotLend',
  },
  {
    id: '2-13',
    layout: {
      x: 12,
      y: 27,
      w: 12,
      h: 9,
    },
    options: [
      { name: 'multi_lenders' },
    ],
    vizState: {
      disabledLegend: true,
      query: {
        measures: [
          'ExcludedReasons.count',
        ],

        timeDimensions: [
          {
            dimension: 'ExcludedReasons.updatedAt',
          },
        ],
        order: {},
        dimensions: [
          'ExcludedReasons.name',
        ],
        filters: [
          {
            dimension: 'ExcludedReasons.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'ExcludedReasons.lender_id',
            operator: 'equals',
            values: [],
          },
        ],
      },
      lenders: [],
      chartType: 'excludedReasonsTable',
    },
    ignoredGlobalOptions: ['groupingByTime'],
    name: 'Competitor will not Lend',
    description: 'COMPETITOR WILL NOT LEND CASES: See the specific criteria and count of times the criteria was not met for competitors.',
    __typename: 'DashboardItem',
    includedSegment: 'criteria',
    identifier: 'competitorWillNotLend',
  },
  {
    id: '2-9',
    layout: {
      x: 0,
      y: 36,
      w: 12,
      h: 9,
    },
    options: [

      { name: 'dimension', type: 'single', disable_empty: true },
      {
        name: 'grouping_by_time',
        options: [
          { label: 'Year', value: 'year' },
          { label: 'Month', value: 'month' },
        ],
      },

    ],
    vizState: {

      disabledLegend: true,
      query: [{
        measures: [
          'CaseResults.averageLendersAffordable',
          'CaseResults.averageMinAffordable',
          'CaseResults.averageMaxAffordable',
          'CaseResults.averageBorrowing',
          'CaseResults.sumRankWeight',
        ],

        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'year',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.loanBorrowing',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.averageRank',
          'CaseResults.affordablePercentage',
          'CaseResults.sumRankWeight',
          'CaseResults.count',
        ],

        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'year',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.loanBorrowing',
        ],
        filters: [
          `CaseResults.LENDER.${currentLenderId}`,
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }],
      chartType: 'benchmarkTable',
    },
    ignoredGlobalOptions: ['groupingByTime'],
    name: 'Your Detailed Benchmarks',
    description: 'Understand how you perform with a larger cross-section of data. Great for quickly identifying areas of attention.',
    __typename: 'DashboardItem',
    includedSegment: 'affordability',
    identifier: 'yourDetailedBenchmarks',
  },
  {
    id: '2-10',
    // newFeature: true,
    layout: {
      x: 12,
      y: 36,
      w: 12,
      h: 9,
    },
    options: [

      { name: 'product_type' },
      { name: 'scenario_order_strategy' },
      { name: 'product' },
      { name: 'lenders_products' },

    ],
    vizState: {
      currentLenderId,
      disabledLegend: true,
      scenarioOrderStrategy: 'initial_rate',
      productType: 'Fixed 2 - 4 year',
      lendersProducts: [
        { lender: [], product: [] },
        { lender: [], product: [] },
        { lender: [], product: [] },
      ],
      query: [
        {
          measures: [
            'CaseResults.count',
          ],
          timeDimensions: [
            {
              dimension: 'CaseResults.updatedAt',
              granularity: 'year',
            },
          ],
          order: {},
          dimensions: [
            'CaseResults.lenderName',
          ],
          filters: [
            {
              dimension: 'CaseResults.caseType',
              operator: 'equals',
              values: [
                'buy_to_let',
              ],
            },
            {
              dimension: 'CaseResults.updatedAt',
              operator: 'afterDate',
              values: [
                '2020-07-01',
              ],
            },
          ],
        },
        {
          measures: [
            'CaseResults.count',
          ],
          timeDimensions: [
            {
              dimension: 'CaseResults.updatedAt',
              granularity: 'year',
            },
          ],
          order: {},
          dimensions: [
            'CaseResults.lenderName',
          ],
          segments: [
            'CaseResults.affordable',
          ],
          filters: [
            {
              dimension: 'CaseResults.caseType',
              operator: 'equals',
              values: [
                'buy_to_let',
              ],
            },
            {
              dimension: 'CaseResults.updatedAt',
              operator: 'afterDate',
              values: [
                '2020-07-01',
              ],
            },
          ],
        },
        {
          measures: [
            'CaseResults.averageBorrowing',
          ],
          timeDimensions: [
            {
              dimension: 'CaseResults.updatedAt',
              granularity: 'year',
            },
          ],
          order: {},
          dimensions: [
            'CaseResults.lenderName',
          ],
          segments: [
            'CaseResults.affordable',
          ],
          filters: [
            {
              dimension: 'CaseResults.caseType',
              operator: 'equals',
              values: [
                'buy_to_let',
              ],
            },
            {
              dimension: 'CaseResults.updatedAt',
              operator: 'afterDate',
              values: [
                '2020-07-01',
              ],
            },
          ],
        },
        statsLenderQuery({ currentLenderId }),
        statsLenderQuery({ currentLenderId }),
        statsLenderQuery({ currentLenderId }),
        statsLenderQuery({ currentLenderId }),
      ],
      chartType: 'sourcingInsightsTable',
    },
    ignoredGlobalOptions: ['groupingByTime'],
    name: 'Sourcing Insights',
    description: "Completes the funnel view and broker journey by bringing together affordability and criteria's impact on sourcing. View performance across all products or focus on how a specific product performs against a competitors product.",
    __typename: 'DashboardItem',
    includedSegment: 'sourcing',
    identifier: 'sourcingInsights',
  },
  {
    id: '2-11',
    newFeature: true,
    layout: {
      x: 0,
      y: 45,
      w: 12,
      h: 9,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'topXBarChart', label: 'Bar' }, { value: 'topXTable', label: 'Table' },
        ],
      },
      { name: 'lenders' },
      {
        name: 'top_position',
        options: [
          { value: 'TopAmong5Lenders', label: 'Among-5' },
          { value: 'TopAmong10Lenders', label: 'Among-10' },
        ],
      },

    ],
    vizState: {
      // titleType: 'TopXLenders',
      custom: 'afford_ranking',
      disabledLegend: true,
      disabledFilters: [3],
      query: [{
        measures: [
          'TopAmong5Lenders.count',
        ],
        timeDimensions: [
          {
            dimension: 'TopAmong5Lenders.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'TopAmong5Lenders.lenderName',
        ],
        filters: [
          {
            dimension: 'TopAmong5Lenders.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'TopAmong5Lenders.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }, {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      }],
      chartType: 'topXBarChart',
      yAxis: {
        formatter: '%',
      },
      lenders: [],
      sessionGranularity: 'month',
    },
    name: 'The Market Ranking / ${topPositionLabel}',
    description: 'Affordable cases: Compare yourself vs. other types of lenders on our panel on how many times you appear among the top 5 or 10',
    __typename: 'DashboardItem',
    includedSegment: 'other',
  },
]
